import React from 'react'

function Vehiculos(props) {
  return (
    <div className='xl:w-1/4 md:w-4/5 h-auto mx-auto bg-white px-5 py-10 flex flex-col justify-center items-centerap-4 rounded-lg shadow-2xl'>
        <img src={props.src} alt={props.alt} className='w-3/4 md:w-2/3 lg:w-1/2 xl:w-auto mx-auto' />
        <p className='font-bold text-azul-30 text-center'>{props.info}</p>
    </div>
  )
}

export default Vehiculos
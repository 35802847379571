import React from 'react'
import ContactButton from './ContactButton'

function ServiciosRowL(props) {
    return (
        <div className='flex flex-col xl:flex-row justify-evenly gap-10 items-center'>
            <div className='xl:w-1/2 2xl:w-1/3 leading-relaxed flex flex-col gap-4 lg:text-lg'>
                <h1 className='text-5xl font-black text-azul-30 leading-tight'>{props.titulo}</h1>
                <p>
                    {props.info1}
                </p>
                <p>
                    {props.info2}
                </p>
                <ContactButton/>
            </div>
            <div className='lg:w-4/5 xl:w-1/2 shadow-3xl'>
                <div className='overflow-hidden rounded-lg'>
                    <img src={props.src} alt={props.alt} className='object-cover w-full hover:scale-110 transition ease-out duration-500' />
                </div>
            </div>
        </div>
    )
}

export default ServiciosRowL
import React from 'react'
// import { motion } from 'framer-motion/dist/framer-motion';
import Ubicaciones from './Ubicaciones';
import Form from './Form';
import { Link } from 'react-router-dom';
import Logo  from "../media/Logo-invertrans-2.png";

// const fadeUp ={
//     visible:{
//         opacity:1,
//         y:0,
//         transition:{duration:0.5}
//     },
//     hidden:{
//         y:20,
//         opacity:0,
//     }
// };

// const fadeLeft = {
//     visible:{
//         opacity:1,
//         x:0,
//         transition:{duration:0.5}
//     },
//     hidden:{
//         opacity:0,
//         x:-20,
//     }
// };
// const container = {
//     hidden: { opacity: 0, x:-100 },
//     visible: {
//       opacity: 1,
//       x: 0,
//       transition: {
//         delayChildren: 0.3,
//         staggerChildren: 0.2
//       }
//     }
// };
// const item = {
//     hidden: { y: 20, opacity: 0 },
//     visible: {
//         y: 0,
//         opacity: 1
//     }
// };
// const fadeIn={
//     hidden:{
//         opacity:0,
//         y:20
//     },
//     visible:{
//         opacity:1,
//         y:0,
//         transition:{duration:0.5}
//     }
// };



function Footer() {
    const hadleScrollToTop =()=>{
        window.scrollTo({
            top:0,
            behavior:"smooth"
        });
    };

    return (
        <div className='p-5 lg:p-10 bg-azul-30 overflow-x-hidden bg-textura-2 bg-no-repeat bg-bottom bg-auto md:bg-auto lg:bg-contain lg:bg-left-bottom xl:bg-left' id='contacto'>
            <div className='flex flex-col-reverse xl:flex-row justify-end items-center gap-10 '>
                <div className='flex flex-col items-center gap-4 md:w-4/5 xl:w-2/5 2xl:w-1/3 p-5 md:p-10 text-gray-100 rounded-large bg-darkblue bg-opacity-20 backdrop-blur-lg'>
                    <h1 className='text-3xl p-3 bg-azul-40 w-max rounded-md font-bold'>
                        Dónde Estamos
                    </h1>
                    <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4'>
                        <Ubicaciones
                            ciudad="San Juan de Pasto."
                            direccion="Calle 2 Sur No. 22-82 Barrio Bachué Oficina Principal."
                            contacto="(602) 7201864"
                        />
                        <Ubicaciones
                            ciudad="Ipiales."
                            direccion="Cra. 1 No. 4b-48 2do piso - Terpél"
                            contacto="313 708 4042"
                        />
                        <Ubicaciones
                            ciudad="Yumbo."
                            direccion="Bloque A3 Oficina 209 CENCAR"
                            contacto="3146780223" />
                        <Ubicaciones
                            ciudad="Bogotá."
                            direccion="Calle 10 No 82-10 Av. Ciudad de Cali"
                            contacto="313 791 1645" />
                        <Ubicaciones
                            ciudad="Itag&#252;i."
                            direccion="Calle 85 No. 448-02 Piso 8vo Oficina 226. Edificio Siglo XXI - Centro Mayorista"
                            contacto="314 678 0221" />
                        <Ubicaciones
                            ciudad="Cúcuta."
                            direccion="Anillo vial oriental - 7N-51 Parque empresarial metropolitano - Boconó"
                            contacto="313 661 6399" />
                        <Ubicaciones
                            ciudad="Buenaventura."
                            direccion="Km 14 Via alterna Centrecarga - Oficina CS1"
                            contacto="313 614 0460" />
                    </div>
                    <div className='flex flex-col items-center gap-2'>
                        <img src={Logo} alt="Invertrans" className='w-36' />
                        <p className='text-sky-50'>gerenciageneral@invertrans.com</p>
                    </div>
                </div>
                <div className='xl:w-1/3 flex flex-col items-center gap-5 w-full md:w-4/5' id='form'>
                    <Link to={"#"} onClick={hadleScrollToTop} className='self-end'><i class="fa-solid fa-angles-up fa-3x text-sky-50 hover:text-sky-500 animate-bounce transition ease-in-out duration-500"></i></Link>
                    <h1 className='text-4xl md:text-5xl lg:text-6xl text-gray-100 font-bold'>Contáctanos</h1>
                    <Form />
                </div>
            </div>
        </div>
    )
}

export default Footer
import React from 'react'

function CardIcon2(props) {
    return (
        <div className='flex flex-col items-center gap-4 p-5 md:w-4/5 mx-auto md:p-10 lg:py-10 lg:px-5 rounded-lg bg-white xl:w-1/3 hover:-translate-y-4 hover:shadow-2xl transition ease-in-out duration-500 group'>
            <i class={props.icon}></i>
            <div className='text-center lg:text-lg'>
                <strong className='text-xl text-azul-30'>{props.titulo}</strong>
                <p>{props.descripcion} </p>
            </div>
        </div>
    )
}

export default CardIcon2
import React from 'react'
import ContactButton from './ContactButton'

function ServiciosRowR(props) {
    return (
        <div className='flex flex-col-reverse xl:flex-row justify-evenly items-center gap-10'>
            <div className='lg:w-4/5 xl:w-1/2 shadow-3xl'>
                <div className='overflow-hidden rounded-lg' >
                    <img src={props.src} alt="" className='object-contain hover:scale-110 transition ease-out duration-500' />
                </div>
            </div>
            <div className='xl:w-1/2 2xl:w-1/3 xl:text-lg leading-relaxed flex flex-col gap-4'>
                <h1 className='text-5xl text-azul-30 font-black leading-tight'>{props.titulo}</h1>
                <p>
                    {props.info1}
                </p>
                <p>
                    {props.info2}
                </p>
                <ContactButton/>
            </div>
        </div>
    )
}

export default ServiciosRowR
import React from 'react';
import { Link } from 'react-router-dom';


function Cards(props) {

  return (
    <div className='md:w-5/6 md:mx-auto xl:w-1/4 flex flex-col sm:flex-row xl:flex-col items-center justify-start p-4 bg-white rounded-lg hover:shadow-2xl hover:-translate-y-2 transition ease-in-out duration-300'>
      <div className='w-5/6 md:w-3/5 h-52 sm:h-64 -mt-24 mb-5 md:-ml-16 md:mr-5 md:mt-0 md:mb-0 overflow-hidden xl:w-11/12 xl:ml-0 xl:mr-0 xl:h-56 xl:-mt-24 xl:mb-5 shadow-xl rounded-lg'>
        <img className='hover:scale-110 transition ease-out duration-700 object-cover object-center w-full h-full' src={props.img} alt={props.alt} />
      </div>
      <div className='md:w-2/3 xl:w-full xl:text-center felx flex-col items-center'>
        <h1 className='text-xl font-bold py-4 text-azul-30'>{props.title}</h1>
        <p className='leading-relaxed'>
          {props.info}
        </p>
        <button className='my-4 border-none px-4 py-3 bg-azul-30 text-white rounded-lg hover:bg-azul-40 transition ease-in-out duration-500'><Link to="/servicios">Conoce más</Link></button>
      </div>
    </div>
  )
}

export default Cards
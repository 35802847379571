import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import {motion} from 'framer-motion/dist/framer-motion';
import img1 from '../media/img13.jpg';
import img2 from '../media/img15.jpg';
import img3 from '../media/img9.jpg';
import img4 from '../media/img10.jpg';
import img5 from '../media/img2.jpg';
import img6 from '../media/img6.jpg';
import img7 from '../media/img3.jpg';
import img8 from '../media/img5.jpg';
import solla from '../media/solla.png';
import alkosto from '../media/alkosto.png';
import ternium from '../media/ternium.png';
import mas from '../media/mas.png';
import sidoc from '../media/sidoc.png';
import brinsa from '../media/brinsa.png';
import italia from '../media/italia.png';
import eternit from '../media/eternit.png';
import solarte from '../media/solarte.png';
import cafeteros from '../media/cafeteros.png';
import corona from '../media/corona.png';
import avansat from '../media/avansat.png';
import euro from '../media/Euroceramica.svg';
import basc from '../media/basc.svg';
import Logo from '../media/Logo-invertrans-2.png';
import Cards from 'components/Cards';

import { Link } from 'react-router-dom';

// const anim ={
//   initial:{
//     x:0
//   },
//   animate:{
//     x:2000,
//     transition:{
//       repeat:Infinity,
//       duration:5,
//     }
//   },

// };
const smothScroll = (target) => {
  const elemnt = document.querySelector(target);
  window.scrollTo({
    behavior: "smooth",
    top: elemnt.offsetTop
  })
};
const scrollToNosotros = () => {
  smothScroll("#nosotros")
};

// const scrollToNosotros = ()=>{
//   const nosotros = document.getElementById("nosotros");
//   if(nosotros){
//       nosotros.scrollIntoView({behavior:"smooth"})
//   }
// };

function Index() {
  return (
    <section className='overflow-x-hidden bg-sky-50 leading-relaxed'>
      <HelmetProvider>
        <Helmet>
          <title>Invertrans Inversiones y Transporte S.A. - Transporte de carga terrestre nacional e internacional</title>
          <meta name='description' content='Invertrans Inversiones y Transporte S.A. es una empresa líder en el transporte de carga terrestre nacional e internacional, cumpliendo con los estándares de calidad a través de la normativa BASC. Ofrecemos una amplia gama de servicios de logística y transporte adaptados a las necesidades de cada cliente.' />
          <meta name='keywords' content='transporte de carga, transporte terrestre, servicio de carga, logística, aduana, DTAI, Pacto Andino, Colombia, Ecuador, Perú, Pasto, Ipiales, Yumbo, Bogotá, Itagui, Cúcuta, Buenaventura, Seguimiento en ruta, Confiable, Eficiente, Seguro, Cumplimiento normativo, Soluciones de transporte, Experiencia, Profesional' />
        </Helmet>
      </HelmetProvider>
      {/* <Menu/> */}
      <div className='w-screen h-screen flex flex-row relative p-5 sm:p-10 md:px-20 sm:py-32 lg:py-40 sm:items-center'>
        <video src="https://invertrans.com/video/video-invertrans.mp4" autoPlay muted loop className='absolute top-0 left-0 w-full h-full object-cover z-0'></video>
        <div className='sm:w-1/2 md:w-2/3 lg:w-2/3 xl:w-2/3 2xl:w-2/5 sm:h-auto bg-black bg-opacity-20 backdrop-blur-sm rounded-large flex flex-col p-5 xl:p-10 justify-center gap-5'>
          <div className='flex flex-col sm:flex-row items-center gap-5'>
            <img src={Logo} alt="Invertrans" className='w-52 sm:hidden' />
            <h1 className='text-5xl text-center sm:text-left sm:text-6xl xl:text-6xl 2xl:text-7xl text-gray-100'>
              <strong className='text-azul-40'>LIDERES</strong> EN <strong className='text-azul-40'>TRANSPORTE</strong> DE <strong className='text-azul-40'>CARGA</strong>
            </h1>
          </div>
          <div>
            <p className='lg:text-xl text-gray-100'>
              Sabemos que la calidad de nuestro servicio es fundamental para el éxito de nuestros clientes,
              y por eso nos aseguramos de cumplir con los más altos estándares de calidad y seguridad a través de la normativa <strong className='text-azul-40'>BASC</strong>.
            </p>
          </div>
          <button onClick={scrollToNosotros} className='self-end bg-azul-30 p-4 rounded-md lg:text-xl text-white font-bold hover:bg-azul-40 transition ease-in-out duration-500'>
            Conoce más
          </button>
        </div>
      </div>
      <div className='w-screen lg:text-lg' id='nosotros'>
        <h1 className='text-center px-5 py-16 md:p-12 xl:p-20 text-6xl sm:text-6xl font-black text-azul-30'>Acerca de nosotros</h1 >
        <div className='w-screen flex flex-col xl:flex-row justify-center items-center gap-10 md:pb-10 xl:pb-20 px-5 sm:px-0'>
          <div className=' md:w-5/6 xl:w-1/3'>
            <h1 className='py-5 text-5xl text-azul-30 font-black'>Nuestra Misión</h1>
            <p className=''>
              Somos una empresa dedicada a prestar un servicio de transporte de carga a
              nivel Nacional e Internacional trabajando con calidad, cumplimiento y ética,
              para la satisfacción de sus clientes, proveedores y comunidad en general;
              nuestro trabajo está encaminado a contribuir con el desarrollo de la región y el
              mejoramiento continuo de la empresa logrando ser más competitivos y
              productivos.<br /> La calidad del servicio prestado surge del talento y
              profesionalismo de nuestra gente, personas competentes y comprometidas
              con su trabajo, su seguridad y la de los Asociados al Negocio.
            </p>
          </div>
          <div className='grid grid-rows-4 grid-flow-col h-80 sm:h-auto md:px-10 lg:w-4/5 xl:w-1/2 gap-1 '>
            <div className='row-span-4 col-span-2 rounded-lg overflow-hidden shadow-2xl'>
              <img className='object-cover h-full object-left hover:scale-110 transition ease-out duration-500' src={img1} alt="Invertrans" />
            </div>
            <div className='row-span-2 col-span-2 rounded-lg overflow-hidden shadow-2xl'>
              <img className='object-cover h-full hover:scale-110 transition ease-out duration-500' src={img2} alt="Invertrans" />
            </div>
            <div className='row-span-2 col-span-2 rounded-lg overflow-hidden shadow-2xl'>
              <img className='object-cover h-full hover:scale-110 transition ease-out duration-500' src={img3} alt="Invertrans" />
            </div>
          </div>
          <div>
          </div>
        </div>
        <div className='w-screen flex flex-col-reverse xl:flex-row p-5 md:p-10 xl:p-20 justify-center gap-10 items-center bg-azul-30 text-sky-50'>
          <div className='h-80 sm:h-auto lg:w-4/5 xl:w-1/2 overflow-hidden rounded-lg shadow-2xl'>
            <img className='object-cover w-full h-full hover:scale-110 transition ease-out duration-500' src={img4} alt="Invertrans" />
          </div>
          <div className='md:w-5/6 xl:w-1/3'>
            <h1 className='text-5xl py-5 font-black text-sky-100'>Nuestra Visión</h1>
            <p className='lg:text-lg'>
              Ser la empresa de transporte líder para el 2026 en el mercado nacional y en los
              países pertenecientes al pacto andino; reconocida por su liderazgo en la
              satisfacción a nuestros clientes internos y externos y respeto por el medio
              ambiente.<br />
              Basada en el conocimiento como una empresa inteligente flexible al cambio donde
              prima el cliente, la calidad, seguridad e integridad y el recurso humano, buscando
              estar a la vanguardia en el servicio del Transporte de Carga.

            </p>
          </div>
        </div>
        <div className='bg-azul-30 w-full px-5 py-16 md:p-16 xl:p-20'>
          <div className='md:w-5/6 xl:w-full 2xl:w-11/12 flex flex-col xl:flex-row justify-center items-stretch text-sky-100 mx-auto'>
            <div className='w-full h-52 xl:h-auto xl:w-1/5 2xl:w-1/6 xl:flex xl:flex-col xl:justify-center bg-sky-50 rounded-t-lg xl:rounded-l-lg xl:rounded-t-none xl:shadow-2xl overflow-hidden'>
              <img src={basc} alt="Logo BASC" className='h-full mx-auto lg:object-contain hover:scale-110 transition ease-in-out duration-500' />
            </div>
            <div className='w-full xl:w-3/5 2xl:w-1/2 flex flex-col gap-5 p-5 bg-black bg-opacity-20 backdrop-blur-sm rounded-b-lg xl:rounded-r-lg xl:rounded-b-none shadow-2xl'>
              <h1 className='text-4xl sm:text-5xl font-black text-sky-100'>Transporte y logistica segura.</h1>
              <p className='lg:text-lg'>
                En INVERTRANS, nos enorgullecemos de cumplir con la certificación de la Norma y Estándares Internacionales
                BASC desde el año 2008. Sabemos que nuestros clientes confían en nosotros para brindar servicios de transporte
                y logística de manera legal, transparente y segura. Por eso, nos comprometemos a seguir mejorando y promoviendo
                la aplicación de esta herramienta de gestión de control y seguridad en nuestra organización.
              </p>
              <button className='w-max bg-azul-40 p-4 rounded-md self-end lg:text-lg text-white font-bold hover:bg-sky-400 hover:-translate-y-1 hover:shadow-2xl transition ease-in-out duration-500'>
                <Link to={'/certificado'}>
                  Conoce más
                </Link>
              </button>
            </div>
          </div>

        </div>
      </div>
      <div className='w-screen p-5 xl:p-10 gap-20  justify-center items-center ' id='servicios'>
        <h1 className='text-6xl text-center py-10 text-azul-30 font-black'>Servicios</h1>
        <div className='flex flex-col xl:flex-row justify-center gap-32 sm:gap-10 lg:gap-5 my-20 sm:my-10 xl:my-24 2xl:w-5/6 mx-auto 2xl:gap-10'>
          <Cards
            img={img5}
            alt="Fotos Invertrans por Sebastian Romero G"
            title="Transporte Terrestre"
            info="Somos una empresa líder en el servicio de carga terrestre nacional y transporte terrestre desde y hacia los 
            principales puertos en el país, así como en el transporte de carga terrestre de exportaciones e importaciones."
          />
          <Cards
            img={img6}
            alt="Foto de Claudio Mota: https://www.pexels.com/es-es/foto/carretera-paisaje-campo-autopista-9580287/"
            title="Pacto Andino"
            info="Transporte de mercancía pacto andino.
            Nos complace ofrecerles nuestro servicio de transporte de mercancía en los países del Pacto Andino: Colombia, Ecuador y Perú."
          />
          <Cards
            img={img7}
            alt="Fotos Invertrans por Sebastian Romero G"
            title="Servicios Logisticos"
            info="Brindamos una amplia gama de servicios relacionados con el comercio exterior, incluyendo servicios de aduana, 
            Documentos de Tránsito de Aduana Internacional (DTAI) y seguimiento en ruta."
          />
          <Cards
            img={img8}
            alt="Fotos Invertrans por Sebastian Romero G"
            title="Servicio De Carga"
            info="En nuestro compromiso por brindarle un servicio de transporte de carga completo y confiable, 
            ofrecemos una amplia flota de vehículos que se adaptan a las necesidades de su carga."
          />
        </div>
      </div>
      <div className='w-screen p-5 md:p-10 bg-textura-1 bg-no-repeat bg-cover bg-center ' id='avansat'>
        <div className='bg-opacity-20 backdrop-blur-md rounded-large w-max p-1 mx-auto'>
          <img src={avansat} alt="Avansat" className='sm:w-80' />
        </div>
        <div className='flex flex-col xl:flex-row items-center gap-6 my-10 sm:w-3/4 sm:mx-auto md:p-5 text-gray-100 lg:text-lg bg-opacity-20 backdrop-blur-md rounded-large'>
          <div className='xl:w-1/2 '>
            <p>
              <strong className='text-bluegray-500 text-xl'>Avansat</strong> es el software TMS (Transportation Management System) que le permite tener un control total de sus operaciones de transporte de
              carga. Con Avansat, podrá gestionar su flota de manera eficiente y rentable, gracias a la visualización completa de sus operaciones y el
              acceso en tiempo real a información clave.
            </p>
          </div>
          <div className='flex flex-col items-center gap-4'>
            <p className='font-bold lg:text-lg'>¡Mejore la eficiencia de su negocio con <strong className='text-bluegray-500'>Avansat</strong>!</p>
            <button className='w-40 h-12 border-none bg-gray-50 font-bold rounded-full overflow-hidden'><a rel='noreferrer' href="https://avansat5.intrared.net:8083/ap/sate_invert/session.php?op=2&usuario=LMMora&app=1" target='_blank'><img src={avansat} alt="Avansat Empresarial" className='object-scale-down h-12 w-40 mx-auto' /></a></button>
          </div>
        </div>
      </div>
      <div className='w-screen p-5 md:p-10 xl:p-20'>
        <h1 className='text-5xl xl:text-6xl font-black text-azul-30 text-center'>Nuestros Clientes</h1>
        <div className='md:w-3/5 lg:w-5/6 grid grid-cols-2 lg:grid-cols-6 gap-4 mx-auto mt-10 lg:my-5'>
          <div className=''><img className='w-full h-28 object-scale-down' src={solla} alt="Solla Nutricion animal" /></div>
          <div className=''><img className='w-full h-28 object-scale-down' src={alkosto} alt="Alkosto" /></div>
          <div className=''><img className='w-full h-28 object-scale-down' src={ternium} alt="Ternium" /></div>
          <div className=''><img className='w-full h-28 object-scale-down' src={mas} alt="Mas" /></div>
          <div className=''><img className='w-full h-28 object-scale-down' src={sidoc} alt="Sidoc" /></div>
          <div className=''><img className='w-full h-28 object-scale-down' src={brinsa} alt="Brinsa Química" /></div>
        </div>
        <div className='md:w-3/5 lg:w-5/6 mx-auto grid grid-cols-2 lg:grid-cols-6 gap-4 mb-10 lg:my-5'>
          <div className=''><img className='w-full h-28 object-scale-down' src={italia} alt="Cerámica Italia" /></div>
          <div className=''><img className='w-full h-28 object-scale-down' src={eternit} alt="Eternit" /></div>
          <div className=''><img className='w-full h-28 object-scale-down' src={solarte} alt="Organizacion Solarte" /></div>
          <div className=''><img className='w-full h-28 object-scale-down' src={cafeteros} alt="Federacion Nacional de Cafeteros de Colombia" /></div>
          <div className=''><img className='w-full h-28 object-scale-down' src={corona} alt="Corona" /></div>
          <div className=''><img className='w-full h-28 object-scale-down' src={euro} alt="Euro ceramica" /></div>
        </div>
      </div>
    </section>
  );
}
export default Index;


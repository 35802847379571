import React from 'react'

function CardIcon(props) {
    return (
        <div className='md:w-4/5 lg:w-1/3 lg:h-80 bg-white rounded-lg flex flex-col justify-center p-10 gap-4 text-center lg:text-lg font-bold hover:shadow-2xl group hover:-translate-y-2 transition ease-in-out duration-300'>
            <i class={props.icon}></i>
            <p>{props.descripcion}</p>
        </div>
    )
}

export default CardIcon

// fa-solid fa-handshake fa-5x text-azul-30 group-hover:text-azul-40 transition ease-in duration-300
import React,{ useState } from 'react';
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
import Logo  from "../media/Logo-invertrans-2.png";
import { Link } from 'react-router-dom';

var oldScrollY = window.scrollY;
const scrollToServicios = ()=>{
    const inicio = document.getElementById("servicios");
    if(inicio){
        inicio.scrollIntoView({behavior:"smooth"});
    }
}
const scrollToAvansat = ()=>{
    const avansat = document.getElementById("avansat");
    if(avansat){
        avansat.scrollIntoView({behavior:"smooth"});
    }
}
const scrollToTraining = ()=>{
    const training = document.getElementById("preguntas");
    if(training){
        training.scrollIntoView({behavior:"smooth"})
    }
}
const scrollToContac = ()=>{
    const contacto = document.getElementById("contacto");
    if(contacto){
        contacto.scrollIntoView({behavior:"smooth"})
    }
}
const variants ={
    visible:{
        y:0,
        opacity:1,
        transition:{
            duration:0.3
        },
    },
    hidden:{
        y:-250,
        opacity:0,
        transition:{
            duration:0.3
        },
    },
    
}
const menu={
    visible:{
        x:0,
        transition:{
            duration:0.5,
            delayChildren: 0.3,
            staggerChildren: 0.2
        }
    },
    hidden:{
        x:600,
    },
}
const item = {
    hidden: { x: 200, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1
    }
  };
function Menu() {
    const [isDown, setIsDown]=useState(true);
    window.onscroll = function(e){
        if(oldScrollY < window.scrollY){
            setIsDown(false)
        }else{
            setIsDown(true)
        }
        oldScrollY=window.scrollY
    }
    const [show,setShow] = useState(false);

  return (
    <div >
        <motion.div variants={variants} animate={isDown ? "visible":"hidden"} className='hidden fixed top-0 left-0 sm:flex sm:flex-row sm:justify-between w-screen py-4 sm:px-4 xl:px-20 z-50 items-center'>
            <Link to={'/'}><img src={Logo} alt="Invertrans " className='sm:w-40 lg:w-48 2xl:w-60' /></Link>
            <div className='flex flex-row justify-end gap-2 w-max text-blue-600 font-bold 2xl:text-xl items-center'>
                <Link to="/" className='hover:text-gray-100 p-3 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out'>Inicio</Link>
                <Link to={"/certificado"} className='hover:text-gray-100 p-3 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out' >Certificación</Link>
                <Link to={"/servicios"} className='hover:text-gray-100 rounded-md p-3 hover:bg-blue-600 transition ease-out duration-300'>Servicios</Link>
                <a href="https://avansat5.intrared.net:8083/ap/sate_invert/session.php?op=2&usuario=LMMora&app=1" rel="noreferrer" target='_blank'  className='hover:text-gray-100 rounded-md p-3 hover:bg-blue-600 transition ease-out duration-300'>Avansat</a>
                <a href="#contacto" rel="noreferrer" className='hover:text-gray-100 rounded-md p-3 hover:bg-blue-600 transition ease-out duration-300'>Contáctanos</a>
            </div>

        </motion.div>
        {/* menu responsive */}
        <div className='sm:hidden fixed top-4 px-4 w-screen flex flex-row items-center justify-end z-50'>
            <p onClick={()=>{setShow(!show)}} className='font-bold text-4xl text-center bg-azul-30 text-sky-100 p-2 rounded-lg transition ease-in-out duration-300'>{show ? <i class="fa-solid fa-xmark text-sky-100 bg-opacity-0"></i> : <i class="fa-solid fa-bars "></i> }</p>
        </div>
        <AnimatePresence>
            {show ? 
            <motion.div variants={menu} initial="hidden" animate="visible" exit="hidden" className='w-screen h-screen bg-azul-30 fixed top-0 left-0 z-40 flex flex-col justify-center gap-1 items-center'>
                <img src={Logo} alt="Brighton " className='w-52' />
                <motion.p onClick={()=>{setShow(!show); scrollToServicios() }} variants={item}  className='text-2xl text-sky-100 font-bold py-3 '><Link to={'/'}>Inicio</Link></motion.p>
                <motion.p onClick={()=>{setShow(!show); scrollToAvansat() }} variants={item} className='text-2xl text-sky-100 font-bold py-3'><Link to={'/certificado'}>Certificación</Link></motion.p>
                <motion.p onClick={()=>{setShow(!show); scrollToTraining() }} variants={item} className='text-2xl text-sky-100 font-bold py-3'><Link to={'/servicios'}>Servicios</Link></motion.p>
                <motion.p onClick={()=>{setShow(!show); scrollToContac() }} variants={item} className='text-2xl text-sky-100 font-bold py-3'><a href="https://avansat5.intrared.net:8083/ap/sate_invert/session.php?op=2&usuario=LMMora&app=1" rel='noreferrer' target='_blank'>Avansat</a></motion.p>
                <motion.p onClick={()=>{setShow(!show); scrollToContac() }} variants={item} className='text-2xl text-sky-100 font-bold py-3'><a href='#contacto'>Contáctanos</a></motion.p>
            </motion.div>
            :null
            }
        </AnimatePresence>
    </div>
  )
}

export default Menu
import React from 'react';
import basc from '../media/basc.svg';
import certificado from '../media/Certificado Basc -2023.jpg';
import DisableRightClick from 'components/DisableRightClick';


function Certificado() {
  DisableRightClick();
  return (
    <div className='w-screen bg-sky-50 leading-relaxed lg:text-lg'>
      <div className='bg-textura-4 bg-cover bg-center h-64 py-16 md:py-32 md:h-auto'>
        <h1 className='text-5xl text-center lg:text-6xl font-black text-gray-100 lg:w-max mx-auto p-5 backdrop-blur-sm rounded-lg'>Certificacion BASC</h1>
      </div>
      <div className='flex flex-col xl:flex-row justify-evenly items-center p-5 pb-10 sm:p-10 gap-10'>
        <div className='flex flex-col 2xl:w-1/3 xl:w-1/2 items-center gap-5'>
          <img src={basc} alt="Logo Basc" className='lg:w-52 w-44'></img>
          <h1 className='font-black text-5xl text-center text-azul-30'>Business Alliance for Secure Commerce</h1>
          <p>
            La certificación BASC nos permite identificar y mitigar los riesgos a los que estamos 
            expuestos en la cadena logística y de transporte. Asimismo, nos permite implementar métodos 
            adecuados para evitar que estos riesgos se materialicen. En caso de que suceda algo imprevisto, 
            estamos preparados para minimizar el impacto y garantizar la continuidad de nuestras operaciones.
          </p>
          <p>
            En INVERTRANS, trabajamos en conjunto con nuestras partes interesadas pertinentes para desarrollar, 
            mantener y mejorar nuestro Sistema de Gestión de Control y Seguridad BASC. Todo esto, con el objetivo 
            de generar confianza en nuestros clientes y proveedores.
          </p>
          <p>
            Nos enorgullece ser una empresa comprometida con la legalidad, transparencia y seguridad en nuestras 
            operaciones. Al confiar en nosotros, nuestros clientes pueden estar seguros de que su carga será transportada 
            de manera responsable y confiable. ¡Contáctanos hoy mismo y descubre cómo podemos ayudarte con tus necesidades 
            de transporte y logística!
          </p>
        </div>
        <div className='md:w-2/3 lg:w-1/2 xl:w-1/3 overflow-hidden shadow-2xl'>
          <img src={certificado} alt="Certificado BASC 2023" className='object-cover'/>
        </div>
      </div>
    </div>
  )
}

export default Certificado
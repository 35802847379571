import React from 'react';
import img17 from '../media/img17.jpg';
import img18 from '../media/img18.jpg';
import img19 from '../media/img11.jpg';
import img20 from '../media/img20.jpg';
import img21 from '../media/img12.jpg';
import img22 from '../media/img4.jpg';
import c2 from '../media/c2.svg';
import c3 from '../media/c3.svg';
import s2 from '../media/3S2.svg';
import s3 from '../media/3S3.svg';
import ServiciosRowL from 'components/ServiciosRowL';
import ServiciosRowR from 'components/ServiciosRowR';
import CardIcon from 'components/CardIcon';
import CardIcon2 from 'components/CardIcon2';
import Vehiculos from 'components/Vehiculos';
import ContactButton from 'components/ContactButton';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Servicios() {
    return (
        <div className='overflow-x-hidden bg-sky-50'>
            <HelmetProvider>
                <Helmet>
                    <title>Invertrans Inversiones y Transporte S.A. - Transporte de carga terrestre nacional e internacional</title>
                    <meta name='description' content='En INVERTRANS, nos especializamos en el transporte terrestre de carga nacional e internacional. Ofrecemos un servicio completo que incluye nacionalización y tránsito aduanero internacional. Nuestra flota de vehículos está altamente capacitada para manejar cualquier tipo de mercancía, garantizando un servicio confiable y eficiente.' />
                    <meta name='keywords' content='transporte de carga, transporte terrestre, servicio de carga, logística, aduana, DTAI, Pacto Andino, Colombia, Ecuador, Perú, Pasto, Ipiales, Yumbo, Bogotá, Itagui, Cúcuta, Buenaventura, Seguimiento en ruta, Confiable, Eficiente, Seguro, Cumplimiento normativo, Soluciones de transporte, Experiencia, Profesional' />
                </Helmet>
            </HelmetProvider>
            <div className='w-screen bg-textura-3 bg-cover bg-no-repeat bg-center py-16 h-64 md:py-32 xl:p-32 md:h-auto overflow-hidden' id='servicios'>
                <h1 className='p-5 text-gray-100 font-black text-5xl lg:text-6xl text-center lg:w-max mx-auto backdrop-blur-sm rounded-lg'> Nuestros Servicios</h1>
            </div>
            <div className='p-5 md:p-10 lg:p-20 w-screen flex flex-col gap-20' id='carga'>
                <ServiciosRowL
                    titulo="Carga Terreste Nacional"
                    info1="En INVERTRANS, ofrecemos un servicio de carga terrestre nacional que se especializa en el 
                    transporte seguro y confiable de mercancías por carretera. Nuestro servicio cubre las 
                    principales ciudades y centros de producción a nivel nacional, por lo tanto puede confiar 
                    en nosotros para entregar sus productos en cualquier lugar de nuestro país."
                    info2="Si está buscando un servicio de carga terrestre confiable y eficiente para sus necesidades 
                    de transporte de mercancías a nivel nacional, no dude en contactarnos. Estamos listos para 
                    brindarle una solución personalizada que se adapte a sus requisitos específicos."
                    src={img17}
                    alt="Fotos Invertrans por Sebastian Romero G"
                />
                <ServiciosRowR
                    titulo="Transporte Terrestre Desde y Hacia Los Principales Puertos"
                    info1="En INVERTRANS, nos especializamos en el transporte terrestre desde y hacia los principales puertos en el país. 
                    Ofrecemos un servicio completo de transporte de carga en contenedores que incluye nacionalización y tránsito aduanero internacional (DTAI)."
                    info2="Contamos con una flota aliada de vehículos y conductores profesionales que están altamente capacitados para manejar cualquier tipo de mercancía y que se adaptan a los tiempos requeridos. Nuestro servicio es flexible y personalizado para cumplir con los requerimientos específicos de cada envío."
                    src={img18}
                    alt="Foto de Tom Fisk: https://www.pexels.com/es-es/foto/fotografia-de-vista-aerea-del-lote-de-furgonetas-de-contenedores-1427107/"
                />
                <div className='2xl:w-2/3 pb-10 lg:pb-0 text-lg leading-relaxed flex flex-col gap-4 mx-auto' id='Andino'>
                    <h1 className='text-5xl text-azul-30 font-black leading-tight text-center'>Transporte De Carga De Exportación e Importación</h1>
                    <p>
                        Para <strong className='text-azul-40'>exportaciones</strong>, contamos con un sistema eficiente y competitivo que se adapta a
                        las exigencias del mercado internacional. Nos encargamos de mover la carga de acuerdo
                        a las programaciones de nuestros clientes, desde sus plantas a los puertos de todo
                        el país, lo que contribuye al desarrollo del comercio exterior. Ofrecemos diferentes
                        tipos de vehículos para mayor flexibilidad en el transporte de mercancía de exportación.
                    </p>
                    <p>
                        Para <strong className='text-azul-40'>importaciones</strong>, nuestro servicio se distingue por buscar siempre la mejor alternativa
                        acorde a sus solicitudes y presupuesto. Realizamos el transporte de mercancía desde todos
                        los puertos del país a las principales ciudades y centros de producción de las diferentes
                        regiones. Contamos con soluciones para cada necesidad en el transporte de mercancía de importaciones.
                    </p>
                    <p>
                        Confíe en nosotros para el transporte de su carga, contamos con experiencia y un equipo altamente
                        capacitado para brindarle un servicio confiable y eficiente. Contáctenos para obtener más información
                        sobre nuestros servicios de transporte de carga terrestre.
                    </p>
                    <ContactButton />
                </div>
            </div>
            <div className='w-screen p-5 md:p-10 lg:p-20 bg-azul-30'>
                <h1 className='text-5xl lg:text-6xl 2xl:w-2/4 mx-auto text-gray-100 font-black text-center leading-tight'>Transporte De Mercancía Pacto Andino</h1>
            </div>
            <div className='p-5 md:p-10 lg:p-20 mx-auto 2xl:w-2/3 leading-relaxed lg:text-lg flex flex-col gap-4'>
                <p>
                    En nuestro compromiso por brindar servicios de transporte de carga confiables y eficientes, nos complace ofrecer
                    nuestro servicio de transporte de mercancía en los países del Pacto Andino: Colombia, Ecuador y Perú.
                </p>
                <p>
                    Sabemos que cada país tiene sus propias regulaciones y especificaciones en cuanto al transporte de mercancía,
                    por lo que nos aseguramos de seleccionar el método de transporte más adecuado para cada envío. Consideramos la
                    naturaleza de la mercancía y la infraestructura en frontera para elegir la opción más óptima, cumpliendo así con
                    las regulaciones y requerimientos normativos de cada país de destino.
                </p>
                <p>
                    Nos aseguramos de que cada envío se realice de manera eficiente y segura, garantizando la satisfacción de nuestros clientes.
                </p>
                <p>
                    Si buscas un servicio de transporte de mercancía andina de confianza, no dudes en contactarnos. Estamos comprometidos en ofrecer
                    soluciones de transporte personalizadas y adaptadas a las necesidades de cada cliente.
                </p>
                <div className='flex flex-col lg:flex-row justify-center md:items-center mx-auto gap-7 lg:gap-5 py-10'>
                    <CardIcon
                        icon="fa-solid fa-handshake fa-5x text-azul-30 group-hover:text-azul-40 transition ease-in duration-300"
                        descripcion="Nuestros conductores son personas comprometidas y responsables"
                    />
                    <CardIcon
                        icon="fa-solid fa-satellite-dish fa-5x text-azul-30 group-hover:text-teal-500 transition ease-in duration-300"
                        descripcion="Por medio del rastreo satelital brindamos a nuestros clientes la tranquilidad de saber dónde se encuentra su carga"
                    />
                    <CardIcon
                        icon="fa-solid fa-file-waveform fa-5x text-azul-30 group-hover:text-amber-300 transition ease-in duration-300"
                        descripcion="Contamos con pólizas que garantizan el transporte de las mercancías
                        para minimizar riesgos"
                    />
                </div>
                <ContactButton />
            </div>
            <div className='bg-azul-30 p-5 md:p-10 lg:p-20'>
                <h1 className='lg:text-6xl text-5xl text-center font-black text-gray-100 mx-auto lg:w-max'>Servicios Logisticos</h1>
            </div>
            <div className='flex flex-col px-5 py-16 md:p-10 lg:p-20 mx-auto items-center gap-20' >
                <ServiciosRowR
                    titulo="ADUANA"
                    info1="Nuestro servicio de Aduanas está diseñado para garantizar que nuestros clientes cumplan con
                    todas las normas legales relacionadas con la importación, exportación y tránsito aduanero.
                    Con nuestro equipo de profesionales altamente capacitados y experimentados, puede estar seguro/a
                    de que sus operaciones aduaneras se manejarán con la mayor precisión y eficiencia posible.
                    Contáctenos hoy mismo para obtener más información sobre cómo podemos ayudarlo/a a cumplir con sus requisitos aduaneros."
                    src={img19}
                    alt="Fotos Invertrans por Sebastian Romero G"
                />
                <ServiciosRowL
                    titulo="DTAI"
                    info1="DTAI, o Documentos de Transito de Aduana Internacional, es un régimen aduanero que ofrece a los clientes 
                    la posibilidad de transitar mercancías de origen nacional o extranjero hacia una zona franca sin pagar los impuestos 
                    correspondientes. Si necesita trasladar mercancías y quiere ahorrar costos, DTAI es la opción ideal para 
                    usted. Contáctenos para obtener más información sobre este servicio aduanero."
                    src={img20}
                    alt="Fotos Invertrans por Sebastian Romero G"
                />
                <ServiciosRowR
                    titulo="Seguimiento En Ruta"
                    info1="En INVERTRANS, ofrecemos un servicio de seguimiento en ruta que opera los 365 días del año, 
                    podemos garantizar la trazabilidad de la ruta origen-destino. Gracias a la tecnología de rastreo satelital, 
                    podemos ofrecer a nuestros clientes la visibilidad en tiempo real del tránsito de sus mercancías. 
                    Nuestro servicio de seguimiento en ruta le garantiza una experiencia confiable y segura. 
                    Contáctenos para conocer más sobre nuestras soluciones logísticas."
                    src={img21}
                    alt="Fotos Invertrans por Sebastian Romero G"
                />
            </div>
            <div className='lg:w-full p-5 md:p-10 lg:p-20 bg-azul-30 text-gray-100 text-center font-black text-5xl lg:text-6xl'>
                <h1>Servicio De Carga</h1>
            </div>
            <div className='p-5 py-16 md:p-10 lg:p-20 leading-relaxed lg:text-lg'>
                <ServiciosRowR
                    titulo="Servicio Exclusivo De Carga FTL"
                    info1="Nuestro servicio exclusivo de carga FTL (Full Truck Load), diseñado para satisfacer las
                    necesidades de aquellos clientes que necesitan transportar cargas completas de manera rápida
                    y segura. Nos comprometemos a brindar a nuestros clientes alternativas convenientes y
                    eficientes que aseguren la entrega de su carga completa en el menor tiempo posible."
                    info2="Si necesita una alternativa conveniente y eficiente para transportar su carga completa,
                    no dude en ponerse en contacto con nosotros. Nuestro equipo de profesionales estará disponible para
                    responder a todas sus preguntas y proporcionarle un presupuesto personalizado para su carga FTL."
                    src={img22}
                    alt="Fotos Invertrans por Sebastian Romero G"
                />
            </div>
            <div className='bg-azul-30 p-5 md:p-10 lg:p-20 '>
                <h1 className='text-5xl xl:text-6xl font-black text-sky-50 text-center leading-normal'>Portafolio de Servicios</h1>
            </div>
            <div className='p-5 md:p-10 lg:p-20 leading-relaxed lg:text-lg flex flex-col items-center justify-center lg:w-2/3 mx-auto gap-10'>
                <div className=''>
                    En Invertrans, creemos en la importancia de proporcionar un servicio de alta calidad y, por lo tanto,
                    nos comprometemos a trabajar en estrecha colaboración con nuestros clientes para entender sus necesidades
                    y ofrecer soluciones a la medida para satisfacerlas. Conoce nuestro portafolio de servicios donde encontraras
                    soluciones de transporte y logística eficientes y personalizadas.
                </div>
                <button className='self-end p-3 bg-azul-30 text-gray-100 font-bold rounded-lg hover:bg-azul-40 transition ease-in-out duration-500 hover:-translate-y-1 hover:shadow-2xl'>
                    <a href="https://invertrans.com/portafolio/INVERTRANS-PORTAFOLIO-2023.pdf" rel="noreferrer" target='_blank' >Portafolio de Servicios</a>
                </button>
            </div>
            <div className='bg-azul-30 p-5 md:p-10 lg:p-20 leading-relaxed lg:text-lg '>
                <h1 className='text-5xl xl:text-6xl font-black text-sky-50 text-center lg:w-max mx-auto leading-normal'>Tipos De Carga</h1>
                <div className='2xl:w-4/5 mx-auto flex flex-col lg:flex-row gap-7 lg:gap-5 py-16 md:py-10 md:px-0 xl:py-20'>
                    <CardIcon2
                        icon="fa-solid fa-dolly fa-5x text-azul-30 group-hover:text-azul-40 transition ease-in-out duration-500"
                        titulo="Transporte a Granel"
                        descripcion="Transportamos aquellas mercancías sin embalaje, como trigo, maiz, torta de soya, carbón y minerales entre otros."
                    />
                    <CardIcon2
                        icon="fa-solid fa-truck-ramp-box fa-5x text-azul-30 group-hover:text-red-500 transition ease-in-out duration-500"
                        titulo="Transporte de Productos Terminados"
                        descripcion="Transportamos mercancías consolidadas en estibas, como alimentos no perecederos, materiales de construccion, entre otros."
                    />
                    <CardIcon2
                        icon="fa-solid fa-truck-moving fa-5x text-azul-30 group-hover:text-green-500 transition ease-in-out duration-500"
                        titulo="Transporte de Contenedores"
                        descripcion="Transportamos mercancías nacionalizadas y en régimen aduanero, desde los puertos hacia cualquier lugar del país"
                    />
                </div>
                <h1 className='text-5xl xl:text-6xl font-black text-sky-50 xl:w-max text-center p-5 md:p-10 mx-auto '>Vehiculos de Carga Disponibles</h1>
                <div className='flex flex-col 2xl:w-4/5 items-center mx-auto text-gray-100 gap-10'>
                    <p className='2xl:w-1/2 text-center'>Los vehículos de carga son esenciales para el transporte de mercancías a lo largo y ancho del país.
                        Cuando se trata de elegir el vehículo de carga adecuado para su negocio, el número de ejes es un factor
                        importante a considerar. Los vehículos de carga con más ejes pueden transportar más peso y ofrecen una
                        mayor estabilidad en la carretera.
                    </p>
                    <div className='w-full flex flex-col xl:flex-row justify-evenly gap-5'>
                        <Vehiculos
                            src={c2}
                            alt="C2"
                            info="Camión de dos ejes, es un vehículo de carga que puede transportar hasta 17.000 kg"
                        />
                        <Vehiculos
                            src={c3}
                            alt="C3"
                            info="El camión de tres ejes, tiene una capacidad de carga máxima de 28.000 kg."
                        />
                        <Vehiculos
                            src={s2}
                            alt="3S2"
                            info="Los tractocamiones de tres ejes con semirremolque de dos ejes tienen una capacidad de carga máxima de 48.000 kg"
                        />
                        <Vehiculos
                            src={s3}
                            alt="3S3"
                            info="Los tractocamiones de tres ejes con semirremolque de tres ejes pueden transportar hasta 52.000 kg de peso"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Servicios
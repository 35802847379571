import React from 'react'
import {motion} from 'framer-motion/dist/framer-motion';


const container = {
    hidden: { opacity: 0, x:200 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };
function Form() {

  return (
    <motion.div variants={container} initial="hidden" whileInView="visible" className='w-full'>
      <form action="https://formsubmit.co/gerenciageneral@invertrans.com" method='post' className='text-warmgray-200 flex flex-col mx-auto w-full xl:w-4/5 gap-3'>
        <input type="hidden" name="_subject" value="Nuevo Contacto Invertrans" />
        <label htmlFor="name" className='text-xl text-gray-100 font-bold'>Nombre Completo</label>
        <input  className='text-azul-10 focus:outline-none focus:ring-2 focus:ring-sky-400 p-2 bg-sky-100 rounded-md' type="text" name='name' required/>
        <label htmlFor="email" className='text-xl text-gray-100 font-bold'>Correo</label>
        <input className='text-azul-10 focus:outline-none focus:ring-2 focus:ring-sky-400 p-2 bg-sky-100 rounded-md'  type="email" name="email" id="" required/>
        <label htmlFor="message" className='text-xl text-gray-100 font-bold'>Escríbenos</label>
        <textarea  className='text-azul-10 focus:outline-none focus:ring-2 focus:ring-sky-400  p-2 h-32 bg-sky-100 rounded-md' name="message" id="text" cols="30" rows="10" required></textarea>
        <input type="hidden" name="_next" value="https://invertrans.com/#form" ></input>
        <input type="hidden" name="_captcha" value="false"/>
        <input type="hidden" name="_template" value="table"></input>
        <button className='bg-azul-40 self-end p-3 text-xl font-bold rounded-md hover:bg-sky-400 transition ease-out duration-500' >
          <input type="submit" value="Enviar" className='cursor-pointer' />
        </button>
      </form>
    </motion.div>
  )
}

export default Form
import React from 'react'

function ContactButton() {
    return (
        <button className='self-end p-3 bg-azul-30 text-gray-100 font-bold rounded-lg hover:bg-azul-40 transition ease-in-out duration-500 hover:-translate-y-1 hover:shadow-2xl'>
            <a href="https://wa.link/1uzldc" rel="noreferrer" target='_blank' >Contáctanos</a>
        </button>
    )
}

export default ContactButton
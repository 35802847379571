import React from 'react'

function Ubicaciones(props) {
  return (
    <div className='w-full flex flex-col gap-1'>
        <h1 className='font-bold text-xl text-sky-400'>{props.ciudad}</h1>
        <div className='group flex flex-row gap-2 w-full items-center '>
            <i class="fa-solid fa-location-dot fa-1x group-hover:text-azul-40 transition ease-out duration-500"></i>
            <p className='group-hover:text-azul-50 transition ease-out duration-500'>
                {props.direccion}
            </p>
        </div>
        <div className='group flex flex-row gap-2 w-full items-center '>
            <i class="fa-solid fa-mobile-screen-button fa-1x group-hover:text-turquesa-10 transition ease-out duration-500"></i>
            <p className='group-hover:text-azul-50 transition ease-out duration-500'>
                {props.contacto}
            </p>
        </div>
    </div>
  )
}

export default Ubicaciones
//import Header from 'components/Header1';
//import Layout from 'layouts/Layout';
import Footer from 'components/Footer';
import Menu from 'components/Menu';
import ScrollToTop from 'components/ScrollToTop';
import Index from 'pages';
import Certificado from 'pages/certificado';
import Servicios from 'pages/servicios';


import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'styles/styles.css';

function App() {
  return (
    <div className='App'>
      <Router>
        <ScrollToTop />
        <Menu />
        <Switch>
          <Route path={'/servicios'}>
            <Servicios />
          </Route>
          <Route path={'/certificado'}>
            <Certificado />
          </Route>
          <Route path={'/'}>
            <Index />
          </Route>
        </Switch>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
